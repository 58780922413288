/*
 * (c) 2021, Michael Hess. All rights reserved.
 *
 * type Props = {
 *   path: string,
 * };
 */

import { AuthState } from '@aws-amplify/ui-components';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { API, Auth } from 'aws-amplify';
import { React, useEffect, useState } from 'react';
import { Link, Route, Switch } from 'react-router-dom';

import { listCampaignMetadata } from './../graphql/customQueries';

import './DnD.css';
import Campaign from './Campaign.js';
import CampaignCard from './CampaignCard.js';
import CampaignCreateCard from './CampaignCreateCard.js';
import DungeonMasterTools from './DungeonMasterTools.js';
import dmToolsImage from '../images/dmtools.jpg';

export default function DnD(props) {
  let [campaigns, setCampaigns] = useState([]);
  let [authState, setAuthState] = useState();
  let [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    handleAuthStateChange(Auth.user != null ? AuthState.SignedIn : null);
  }, []);

  const handleAuthStateChange = (nextAuthState) => {
    setAuthState(nextAuthState);
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);

  async function fetchCampaigns() {
    const apiData = await API.graphql({
      query: listCampaignMetadata,
    }).catch((e) => setErrorMessage(e.errors[0]?.message));
    setCampaigns(apiData.data.listDNDCampaigns.items);
  }

  const routes = campaigns.map((campaign) => {
    const path = props.path + '/' + campaign.short_name;
    return (
      <Route key={campaign.id} path={path}>
        <Campaign
          id={campaign.id}
          name={campaign.name}
          parentPath={props.path}
          path={path}
        />
      </Route>
    );
  });

  const cards = campaigns
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
    .map((campaign) => (
      <CampaignCard
        key={campaign.id}
        campaignMetadata={campaign}
        parentPath={props.path}
        refetchCampaigns={fetchCampaigns}
      />
    ));

  const dmToolsPath = props.path + '/dmTools';
  return (
    <div>
      {authState == AuthState.SignedIn ? (
        <div className='dnd'>
          <Switch>
            <Route path={dmToolsPath}>
              <DungeonMasterTools parentPath={props.path} />
            </Route>
            {routes}
            <Route path={props.path}>
              <div className='campaign-card'>
                <Link to={dmToolsPath}>
                  <div className='campaign-link'>
                    <img
                      src={dmToolsImage}
                      alt='Dungeon Master Tools'
                      className='campaign-image'
                    />
                    <h1>Dungeon Master Tools</h1>
                  </div>
                </Link>
              </div>
              <h1>D&D Campaigns</h1>
              {cards}
              <CampaignCreateCard refetchCampaigns={fetchCampaigns} />
            </Route>
          </Switch>
        </div>
      ) : (
        <AmplifyAuthenticator handleAuthStateChange={handleAuthStateChange}>
          <AmplifySignIn slot='sign-in' hideSignUp />
        </AmplifyAuthenticator>
      )}
      {errorMessage}
    </div>
  );
}
