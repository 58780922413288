/*
 * (c) 2021, Michael Hess. All rights reserved.
 */

import React from 'react';

import './Contact.css';
import Construction from './../shared/Construction';

export default function Contact() {
  const message = (
    <div>
      <p>The contact form is still under construction.</p>
      <p>
        For now, you can contact me at the following email address:{' '}
        <a href='mailto:michaeldhess12@gmail.com?subject=Contact MichaelDHess'>
          michaeldhess12@gmail.com
        </a>
        .
      </p>
    </div>
  );
  return (
    <div className='contact'>
      <h1>Contact</h1>
      <Construction message={message} />
      {/*<p>
          <b>Or complete the form below:</b>
        </p>
        <form className='form'>
          <fieldset className='fieldset'>
            <legend>Name:</legend>
            <div className='first-name-group'>
              <input type='text' name='firstName' />
              <label htmlFor='firstName'>First</label>
            </div>
            <div className='last-name-group'>
              <input type='text' name='lastName' />
              <label htmlFor='lastName'>Last</label>
            </div>
          </fieldset>
          <fieldset className='fieldset'>
            <legend>Email:</legend>
            <input type='text' name='email' />
          </fieldset>
          <fieldset className='fieldset'>
            <legend>Subject:</legend>
            <input type='text' name='subject' />
          </fieldset>
          <fieldset className='fieldset'>
            <legend>Body:</legend>
            <textarea type='text' name='body' />
          </fieldset>
          <div className='button'>
            <button type='submit'>Send Message</button>
          </div>
        </form>*/}
    </div>
  );
}
