/*
 * (c) 2021, Michael Hess. All rights reserved.
 */

import React from "react";

import "./Footer.css";

export default function Footer() {
  return (
    <footer className="footer">
      Icons made by{" "}
      <a href="https://www.freepik.com" title="Freepik">
        Freepik
      </a>{" "}
      from{" "}
      <a href="https://www.flaticon.com/" title="Flaticon">
        www.flaticon.com
      </a>
      <span className="barrier">|</span>© 2021,{" "}
      <a href="https://www.michaeldhess.com/">Michael Hess</a>. All rights
      reserved.
    </footer>
  );
}
