/*
 * (c) 2021, Michael Hess. All rights reserved.
 *
 * type Props = {
 *   refetchCampaigns: () => void,
 * }
 */

import { React, useState } from 'react';

import plus from '../images/plus.png';

import CampaignEditCard from './CampaignEditCard';

export default function CampaignCreateCard(props) {
  let [isEditing, setIsEditing] = useState(false);

  return (
    <div>
      {isEditing ? (
        <CampaignEditCard
          setIsEditing={setIsEditing}
          refetchCampaigns={props.refetchCampaigns}
        />
      ) : (
        <button
          className='create-button'
          onClick={() => setIsEditing(!isEditing)}
        >
          <img src={plus} alt='Create New Campaign' width='16' /> Create New
          Campaign
        </button>
      )}
    </div>
  );
}
