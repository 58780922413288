/*
 * (c) 2021, Michael Hess. All rights reserved.
 */

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';

import Construction from './shared/Construction.js';
import Contact from './contact/Contact.js';
import DnD from './dnd/DnD.js';
import Footer from './footer/Footer.js';
import Header from './header/Header.js';

export default function App() {
  const isMobile = useMediaQuery({
    // Using 801px as the min width for the web version.
    maxWidth: 800,
  });

  // Routes
  const homePath = '/';
  const dndPath = '/dnd';
  const contactPath = '/contact';

  const homepageMessage = (
    <div>
      <p>The homepage is still under construction.</p>
      <p>
        You're probably here for the <Link to={dndPath}>D&D section</Link>,
        anyway.
      </p>
    </div>
  );

  return (
    <BrowserRouter>
      <div className={'app-contents-wrapper' + (isMobile ? ' mobile' : '')}>
        <Header
          homePath={homePath}
          dndPath={dndPath}
          contactPath={contactPath}
          isMobile={isMobile}
        />
        <div className='app-contents'>
          <Switch>
            <Route path={dndPath}>
              <DnD path={dndPath} />
            </Route>
            <Route path={contactPath}>
              <Contact />
            </Route>
            <Route path={homePath}>
              <Construction message={homepageMessage} />
            </Route>
          </Switch>
        </div>
      </div>
      <Footer />
    </BrowserRouter>
  );
}
