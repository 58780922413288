/*
 * (c) 2021, Michael Hess. All rights reserved.
 *
 * type Props = {
 *   homePath: string,
 *   dndPath: string,
 *   contactPath: string,
 *   isMobile: boolean,
 * };
 */

import { React, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import './Header.css';

export default function Header(props) {
  const [navIsClicked, setNavIsClicked] = useState(false);

  const location = useLocation();
  let path = 'home';
  if (location.pathname.includes(props.dndPath)) {
    path = 'dnd';
  } else if (location.pathname.includes(props.contactPath)) {
    path = 'contact';
  }

  const hamburger = props.isMobile ? (
    <button
      onClick={() => setNavIsClicked(!navIsClicked)}
      className='navButton'
    >
      <hr />
      <hr />
      <hr />
    </button>
  ) : null;

  const showNav = !props.isMobile || navIsClicked;
  return (
    <header className='header'>
      <h1>
        <a href={props.homePath}>MichaelDHess</a>
      </h1>
      {hamburger}
      {showNav ? (
        <div className='links'>
          <h2 className={path === 'home' ? 'isSelected' : null}>
            <Link to={props.homePath} onClick={() => setNavIsClicked(false)}>
              Home
            </Link>
          </h2>
          <h2 className={path === 'dnd' ? 'isSelected' : null}>
            <Link to={props.dndPath} onClick={() => setNavIsClicked(false)}>
              D&D
            </Link>
          </h2>
          <h2 className={path === 'contact' ? 'isSelected' : null}>
            <Link to={props.contactPath} onClick={() => setNavIsClicked(false)}>
              Contact
            </Link>
          </h2>
        </div>
      ) : null}
    </header>
  );
}
