/*
 * (c) 2021, Michael Hess. All rights reserved.
 *
 * type Props = {
 *   campaignMetadata?: ?{
 *     id: string,
 *     short_name: ?string,
 *     name: ?string,
 *     card_image: ?string,
 *   },
 *   setIsEditing: (boolean) => void,
 *   refetchCampaigns: () => void,
 * }
 */

import { API } from 'aws-amplify';
import { React, useState } from 'react';

import { createDndCampaign, updateDndCampaign } from './../graphql/mutations';

export default function CampaignEditCard(props) {
  const metadata = props.campaignMetadata;

  let [isDisabled, setIsDisabled] = useState(false);
  let [name, setName] = useState(metadata?.name != null ? metadata.name : '');
  let [shortName, setShortName] = useState(
    metadata?.short_name != null ? metadata.short_name : ''
  );
  let [imgSrc, setImgSrc] = useState(
    metadata?.card_image != null ? metadata.card_image : ''
  );
  let [errorMessage, setErrorMessage] = useState();

  async function onSave() {
    setIsDisabled(true);
    let query, inputVariables;
    if (props.campaignMetadata != null) {
      query = updateDndCampaign;
      inputVariables = { id: props.campaignMetadata.id };
    } else {
      query = createDndCampaign;
      inputVariables = {};
    }
    inputVariables['name'] = name !== '' ? name : null;
    inputVariables['short_name'] = shortName !== '' ? shortName : null;
    inputVariables['card_image'] = imgSrc !== '' ? imgSrc : null;

    await API.graphql({
      query: query,
      variables: {
        input: inputVariables,
      },
    }).catch((e) => setErrorMessage(e.errors[0])?.message);
    props.refetchCampaigns();
    props.setIsEditing(false);
    setIsDisabled(false);
  }

  return (
    <div className='campaign-card'>
      <div className='edit-card'>
        <div className='input'>
          <label>Campaign Name</label>
          <input
            onChange={(e) => setName(e.target.value)}
            value={name}
            name='name'
            placeholder='The Convergence'
          />
        </div>
        <div className='input'>
          <label>Shortcut</label>
          <input
            onChange={(e) => setShortName(e.target.value)}
            value={shortName}
            name='shortName'
            placeholder='convergence'
          />
        </div>
        <div className='input'>
          <label>Image URL</label>
          <input
            onChange={(e) => setImgSrc(e.target.value)}
            value={imgSrc}
            name='imageUrl'
            placeholder='https://www.michaeldhess.com/convergence.png'
          />
        </div>
        {errorMessage}
      </div>
      <button
        className='cancel-button'
        onClick={() => props.setIsEditing(false)}
        disabled={isDisabled}
      >
        Cancel
      </button>
      <button
        className='save-button'
        onClick={() => onSave()}
        disabled={isDisabled}
      >
        Save
      </button>
    </div>
  );
}
