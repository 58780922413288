/*
 * (c) 2021, Michael Hess. All rights reserved.
 *
 * type Props = {
 *   parentPath: string,
 * };
 */

import { React, useState } from 'react';

import './DungeonMasterTools.css';
import Construction from './../shared/Construction';
import DMToolsBattle from './DMToolsBattle';

export default function DungeonMasterTools(props) {
  const [tab, setTab] = useState(0);

  let content;
  if (tab === 0) {
    content = <DMToolsBattle />;
  } else {
    content = <Construction />;
  }

  return (
    <div className='dm-tools'>
      <h1 className='dm-tools-header'>
        <a href={props.parentPath}>D&D</a> > Dungeon Master Tools{' '}
      </h1>
      <div className='dm-tools-content'>
        <div className={'nav-tabs ' + tab}>
          <button
            className={tab === 0 ? 'isSelected' : ''}
            onClick={() => setTab(0)}
          >
            For Battle
          </button>
          <button
            className={tab === 1 ? 'isSelected' : ''}
            onClick={() => setTab(1)}
          >
            For Traveling
          </button>
          <button
            className={tab === 2 ? 'isSelected' : ''}
            onClick={() => setTab(2)}
          >
            For Town
          </button>
        </div>
        {content}
      </div>
    </div>
  );
}
