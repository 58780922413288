/*
 * (c) 2021, Michael Hess. All rights reserved.
 * type Props = {
 *   message?: ?React.Node,
 * }
 */

import React from 'react';

import './Shared.css';

import construction from '../images/construction.png';

export default function Construction(props) {
  let message;
  if (props.message != null) {
    message = <div>{props.message}</div>;
  } else {
    message = (
      <div>
        <p>This part of the site is still under construction.</p>
        <p>Thank you for your patience.</p>
      </div>
    );
  }

  return (
    <div className='construction'>
      {message}
      <img src={construction} alt='' />
    </div>
  );
}
