/*
 * (c) 2021, Michael Hess. All rights reserved.
 */

import { React, useState } from 'react';

export default function DMToolsBattle(props) {
  const [roll20Search, setRoll20Search] = useState('Fireball');
  const [spellWindow, setSpellWindow] = useState(
    'https://roll20.net/compendium/dnd5e/Fireball'
  );
  const [dndBeyondSearch, setDndBeyondSearch] = useState('');

  const makeLinkItem = (link, text) => {
    return (
      <li>
        <a href={link} rel='noreferrer' target='_blank'>
          {text}
        </a>
      </li>
    );
  };

  let spellFrame = null;
  if (spellWindow != null) {
    spellFrame = (
      <iframe
        title='Spells'
        src={spellWindow}
        width='99.5%'
        height='495'
        onError={(e) => console.log(e)}
        referrerPolicy='no-referrer'
      />
    );
  }

  return (
    <table>
      <tbody>
        <tr>
          <td className='first-column'>
            <h3>Encounter Builders</h3>
            <ul>
              {makeLinkItem('https://roll20.net/welcome', 'Roll20')}
              {makeLinkItem(
                'https://kobold.club/fight/#/encounter-builder',
                'Kobold Fight Club - Encounter Builder'
              )}
              <li>
                <b>Donjon RPG Tools</b>
                <ul>
                  {makeLinkItem(
                    'https://donjon.bin.sh/5e/calc/enc_size.html',
                    'Encounter size calculator'
                  )}
                  {makeLinkItem(
                    'https://donjon.bin.sh/5e/random/#type=encounter',
                    'Random encounter generator'
                  )}
                </ul>
              </li>
            </ul>
            <h3>Monsters</h3>
            <ul>
              {makeLinkItem(
                'https://www.dndbeyond.com/sources/mm',
                'D&D Beyond Monster Manual'
              )}
              {makeLinkItem(
                'https://drive.google.com/file/d/0B4jAv0Wgv9taVnhSZEgxaDdMakk/view',
                'Monster-a-day Compendium'
              )}
              {makeLinkItem(
                'https://5e.tools/bestiary.html',
                '5e Tools Bestiary'
              )}
            </ul>
            <h3>During encounters</h3>
            <ul>
              {makeLinkItem(
                'https://www.improved-initiative.com/e/',
                'Improved Initiative'
              )}
              <li>
                <b>D&D Beyond</b>
                <ul>
                  {makeLinkItem(
                    'https://www.dndbeyond.com/sources/phb/spells',
                    'Spell List'
                  )}
                  {makeLinkItem(
                    'https://www.dndbeyond.com/sources/phb/appendix-a-conditions',
                    'Conditions'
                  )}
                </ul>
              </li>
              {makeLinkItem(
                'https://roll20.net/compendium/dnd5e/Index%3ASpells',
                'Roll20 Spell List'
              )}
              {makeLinkItem(
                'https://arcaneeye.com/spells/',
                'ArcaneEye Spell List'
              )}
            </ul>
            <h3>Miscellaneous</h3>
            <ul>
              {makeLinkItem(
                'https://tabletopaudio.com/custom_sp.html',
                'Tabletop Audio'
              )}
            </ul>
          </td>
          <td className='second-column'>
            <h3>Spell search</h3>
            <div>
              Search Roll20 (input any spell name and hit "Enter").
              <input
                value={roll20Search}
                onChange={(e) => setRoll20Search(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setSpellWindow(
                      'https://roll20.net/compendium/dnd5e/' + roll20Search
                    );
                  }
                }}
              />
            </div>
            <div className='spell-frame'>{spellFrame}</div>
            <div className=''>
              Go to the D&D Beyond spell page (input any spell name and hit
              "Enter").
              <input
                value={dndBeyondSearch}
                onChange={(e) => setDndBeyondSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    window.open(
                      'https://www.dndbeyond.com/spells/' +
                        dndBeyondSearch.split(' ').join('-')
                    );
                  }
                }}
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
