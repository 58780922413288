/*
 * (c) 2021, Michael Hess. All rights reserved.
 *
 * type Props = {
 *   campaignMetadata: {
 *     id: string,
 *     short_name: ?string,
 *     name: ?string,
 *     card_image: ?string,
 *     createdAt: ?number,
 *   },
 *   parentPath: string,
 *   refetchCampaigns: () => void,
 * }
 */

import { React, useState } from 'react';
import { Link } from 'react-router-dom';

import pencil from '../images/pencil.png';
import secondaryColor1 from '../images/secondaryColor1.png';

import CampaignEditCard from './CampaignEditCard';

export default function CampaignCard(props) {
  let [isEditing, setIsEditing] = useState(false);

  let cardContents;
  if (isEditing) {
    cardContents = (
      <CampaignEditCard
        campaignMetadata={props.campaignMetadata}
        setIsEditing={setIsEditing}
        refetchCampaigns={props.refetchCampaigns}
      />
    );
  } else {
    cardContents = (
      <div>
        <Link to={props.parentPath + '/' + props.campaignMetadata.short_name}>
          <div className='campaign-link'>
            <img
              src={
                props.campaignMetadata.card_image != null
                  ? props.campaignMetadata.card_image
                  : secondaryColor1
              }
              alt={props.campaignMetadata.name}
              className='campaign-image'
            />
            <h1>{props.campaignMetadata.name}</h1>
          </div>
        </Link>
        <button
          className='edit-button'
          onClick={() => setIsEditing(!isEditing)}
        >
          <img src={pencil} alt='Edit' width='16' /> Edit
        </button>
      </div>
    );
  }

  return <div className='campaign-card'>{cardContents}</div>;
}
