/*
 * (c) 2021, Michael Hess. All rights reserved.
 *
 * type Props = {
 *   id: string,
 *   parentPath: string,
 * };
 */

import { API } from 'aws-amplify';
import { React, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Editor as TinyMCEEditor } from '@tinymce/tinymce-react';

import { getDndCampaign } from './../graphql/queries';
import { updateDndCampaign } from './../graphql/mutations';

import './Campaign.css';

import CampaignContents from './CampaignContents';

export default function Campaign(props) {
  let [campaign, setCampaign] = useState();
  let [isEditing, setIsEditing] = useState(false);
  let [isDisabled, setIsDisabled] = useState(false);
  let [content, setContent] = useState();
  let [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    fetchCampaign(props.id);
  }, [props.id]);

  async function fetchCampaign(id) {
    const apiData = await API.graphql({
      query: getDndCampaign,
      variables: { id: id },
    });
    setCampaign(apiData.data.getDNDCampaign);
    setContent(apiData.data.getDNDCampaign?.content);
  }

  const onCancel = () => {
    setIsDisabled(true);
    setContent(campaign?.content);
    setIsEditing(false);
    setIsDisabled(false);
  };

  async function onSave() {
    setIsDisabled(true);
    const refetchedData = await API.graphql({
      query: getDndCampaign,
      variables: { id: props.id },
    });
    const refetchedCampaign = refetchedData.data.getDNDCampaign;
    if (refetchedCampaign?.content === campaign.content) {
      await API.graphql({
        query: updateDndCampaign,
        variables: { input: { id: campaign.id, content: content } },
      });
      fetchCampaign(props.id);
      setIsEditing(false);
    } else {
      setErrorMessage(
        <div>
          <p>
            This information has been overwritten since you started editing.
          </p>
          <p>
            (Last update at {new Date(refetchedCampaign.updatedAt).toString()})
          </p>
          <p>You will not be able to save until you refresh the page.</p>
        </div>
      );
    }
    setIsDisabled(false);
  }

  if (campaign == null) {
    return null;
  }
  const currPath = props.parentPath + '/' + campaign.short_name;
  return (
    <div id='campaign' className='campaign'>
      <Switch>
        <Route path={currPath}>
          <h1 className={'campaign-header' + (isEditing ? ' editing' : '')}>
            <a href={props.parentPath}>D&D</a> {'>'} {campaign.name}
            {isEditing ? null : (
              <button
                className='edit-button'
                disabled={isDisabled}
                onClick={() => setIsEditing(!isEditing)}
              >
                edit
              </button>
            )}
          </h1>
          {errorMessage}
          {isEditing === true ? (
            <div>
              <button
                className='cancel-button'
                disabled={isDisabled}
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                className='save-button'
                disabled={isDisabled}
                onClick={onSave}
              >
                Save
              </button>
              <TinyMCEEditor
                disabled={isDisabled}
                apiKey='vtkddhlmuxt14ww8xo3yr1zjblxecahkofphmkw1qfhnc7cl'
                initialValue={content}
                init={{
                  resize: false,
                  height: '500',
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview',
                    'anchor searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount toc',
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic strikethrough ' +
                    'backcolor | alignleft aligncenter alignright ' +
                    'alignjustify | bullist numlist outdent indent | toc ' +
                    'image | removeformat | help',
                  skin: 'oxide-dark',
                }}
                onEditorChange={(content, _editor) => setContent(content)}
              />
            </div>
          ) : (
            <CampaignContents
              content={content}
              parentPath={currPath}
              isEditing={isEditing}
            />
          )}
          <div className='last-updated'>
            Last updated: {new Date(campaign.updatedAt).toLocaleString('en-US')}
          </div>
        </Route>
      </Switch>
    </div>
  );
}
