/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://qhcgwjiknzew7epxjvenjs4hs4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-vfbqviggujaq7ouycpujloqm6y",
    "aws_cognito_identity_pool_id": "us-east-1:59a53cd7-a665-41e7-b146-b85cef1cecd7",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_pi4jo0R1h",
    "aws_user_pools_web_client_id": "5iunsid35drl3qbgpns5ev7bms",
    "oauth": {}
};


export default awsmobile;
