const listCampaignMetadata = `
  query ListCampaignMetadata {
    listDNDCampaigns {
      items {
        id
        short_name
        name
        card_image
        createdAt
      }
    }
  }
`;

export { listCampaignMetadata };
