/*
 * (c) 2021, Michael Hess. All rights reserved.
 *
 * type Props = {
 *   content: HTML,
 *   parentPath: string,
 *   isEditing: boolean,
 * };
 */

import { React, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import copy from '../images/inverted-copy.png';

export default function Campaign(props) {
  const location = useLocation();

  const contents = document.getElementById('campaign-contents');
  useEffect(() => {
    setupScrollToLinks(props.parentPath, contents);
  }, [props.parentPath, props.content, contents]);

  const setupScrollToLinks = (parentPath, contents) => {
    const appendButton = (header, size) => {
      const image = document.createElement('img');
      image.src = copy;
      image.alt = 'Copy Header Link';
      image.width = size;
      const button = document.createElement('button');
      button.className = 'copy-link';
      button.onclick = () => {
        const currentURL = window.location.href.split(parentPath)[0];
        navigator.clipboard.writeText(
          currentURL + parentPath + '#' + header.id
        );
        const oldOnClick = button.onclick;
        const oldinnerHMTL = button.innerHTML;
        button.onclick = () => {};
        button.innerHTML = 'link copied!';
        setTimeout(() => {
          button.onclick = oldOnClick;
          button.innerHTML = oldinnerHMTL;
        }, 2000);
      };
      button.appendChild(image);
      header.appendChild(button);
    };

    if (contents == null) {
      return null;
    } else {
      for (let h1 of contents.getElementsByTagName('h1')) {
        appendButton(h1, 18);
      }
      for (let h2 of contents.getElementsByTagName('h2')) {
        if (h2.innerHTML !== 'Table of Contents') {
          appendButton(h2, 16);
        }
      }
      for (let h3 of contents.getElementsByTagName('h3')) {
        appendButton(h3, 12);
      }
    }
  };

  useEffect(() => {
    scrollToID(location);
  }, [contents, location]);

  const scrollToID = (location) => {
    if (location.hash != null) {
      const el = document.getElementById(location.hash.split('#')[1]);
      if (el != null) {
        const y = el.getBoundingClientRect().top + window.pageYOffset + -80;
        setTimeout(() => window.scrollTo({ top: y }), 2);
      }
    }
  };

  return (
    <div id='campaign-contents' className='campaign-contents'>
      <div dangerouslySetInnerHTML={{ __html: props.content }} />
    </div>
  );
}
