/*
 * (c) 2021, Michael Hess. All rights reserved.
 */

import config from './aws-exports';
import Amplify from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';

import App from './App';

Amplify.configure(config);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('app')
);
