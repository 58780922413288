/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDndCampaign = /* GraphQL */ `
  mutation CreateDndCampaign(
    $input: CreateDNDCampaignInput!
    $condition: ModelDNDCampaignConditionInput
  ) {
    createDNDCampaign(input: $input, condition: $condition) {
      id
      name
      short_name
      content
      card_image
      createdAt
      updatedAt
    }
  }
`;
export const updateDndCampaign = /* GraphQL */ `
  mutation UpdateDndCampaign(
    $input: UpdateDNDCampaignInput!
    $condition: ModelDNDCampaignConditionInput
  ) {
    updateDNDCampaign(input: $input, condition: $condition) {
      id
      name
      short_name
      content
      card_image
      createdAt
      updatedAt
    }
  }
`;
export const deleteDndCampaign = /* GraphQL */ `
  mutation DeleteDndCampaign(
    $input: DeleteDNDCampaignInput!
    $condition: ModelDNDCampaignConditionInput
  ) {
    deleteDNDCampaign(input: $input, condition: $condition) {
      id
      name
      short_name
      content
      card_image
      createdAt
      updatedAt
    }
  }
`;
